import React from 'react';

import { usePayment } from '@/features/wallet';
import { DepositButton } from '@/entities/deposit';

import { ProfileDesktop } from './profile-desktop';
import { ProfileBalance } from './profile-balance';
import { ProfileMobile } from './profile-mobile';

export const AccountInfo: React.FC = () => {
  const { openDeposit } = usePayment();

  return (
    <>
      <ProfileDesktop />
      <ProfileBalance />
      <DepositButton onClick={openDeposit} />
      <ProfileMobile />
    </>
  );
};
