import { create } from 'zustand';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

interface SidebarState {
  sidebarVisible: boolean | null;
  overlaySidebarVisible: boolean;
  setInitialSidebarVisible: (visible: boolean) => void;
  showSidebar: () => void;
  hideSidebar: () => void;
  toggleOverlaySidebar: (visible: boolean) => void;
  overlaySidebarPath: string[];
}

const initialState = {
  overlaySidebarPath: [],
  sidebarVisible: null,
  overlaySidebarVisible: false
};

export const useSidebar = create<SidebarState>((set) => ({
  ...initialState,
  setInitialSidebarVisible: (visible: boolean) => set({ sidebarVisible: visible }),
  showSidebar: () => {
    set({ sidebarVisible: true });
    safeLocalStorage.setItem('sidebarVisible', 'true');
  },
  hideSidebar: () => {
    set({ sidebarVisible: false });
    safeLocalStorage.setItem('sidebarVisible', 'false');
  },
  toggleOverlaySidebar: (visible: boolean) => set({ overlaySidebarVisible: visible })
}));
