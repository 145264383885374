import { ProfileIcon } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { RoutesConfig } from '@/shared/routes/config';

import { Item } from './item';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';

export const ProfileDesktop = () => {
  const t = useScopedI18n('header');
  const pathname = usePathname();
  const active = pathname.includes(RoutesConfig.profile);

  return (
    <Item
      href={RoutesConfig.profile}
      testIdModificator="profile"
      className="hidden flex-row flex-nowrap md:flex"
      active={active}
    >
      <ProfileIcon
        className={classNames(
          'mr-1 group-hover:text-icon-brand',
          active ? 'text-icon-brand' : 'text-icon-tertiary'
        )}
      />
      <span>{t('profile')}</span>
    </Item>
  );
};
