import { UserIcon } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { RoutesConfig } from '@/shared/routes/config';

import { Item } from './item';

export const ProfileMobile = () => {
  return (
    <Item href={RoutesConfig.profile} testIdModificator="profile" className="ml-1 md:hidden">
      <div className="boreder-primary color-primary flex h-[32px] !w-[32px] items-center justify-center rounded-1 border text-primary active:bg-button-brand-click">
        <UserIcon size={12} />
      </div>
    </Item>
  );
};
