import { Button } from '@/shared/ui';
import { useScopedI18n } from '@/shared/locales/client';
import { RoutesConfig } from '@/shared/routes/config';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { sendHeaderLoginButtonEvent } from '@/shared/lib/gtm/events/login-events';
import { sendHeaderRegistrationButtonEvent } from '@/shared/lib/gtm/events/registration-events';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

type ModalType = 'login' | 'registration' | null | 'recovery';

export const AuthButtons = () => {
  const t = useScopedI18n('header');
  const testIdForLogin = getDataTestId({ block: 'header', nameForTargetElement: 'loginButton' });
  const testIdForRegistration = getDataTestId({
    block: 'header',
    nameForTargetElement: 'registrationButton'
  });
  const router = useRouter();

  const searchParams = useSearchParams();

  const pathname = usePathname();
  const login = searchParams.get('login');
  const registration = searchParams.get('registration');
  const recovery = searchParams.get('recovery');

  const computedModalType = useMemo(() => {
    if (login) return 'login';
    if (registration) return 'registration';
    if (recovery) return 'recovery';
    return null;
  }, [login, registration, searchParams]);

  const [modalType, setModalType] = useState<ModalType>(computedModalType || null);

  useEffect(() => {
    setModalType(computedModalType);
  }, [computedModalType, searchParams]);

  return (
    <div className="flex items-center">
      <div className="md:ml-0 lg:ml-6t">
        <div className="flex md:hidden">
          {modalType !== 'login' && (
            <Button
              dataTestid={testIdForLogin}
              variant="border"
              size="xs"
              onClick={() => {
                router.push(RoutesConfig.login);
                sendHeaderLoginButtonEvent();
              }}
            >
              {t('signIn')}
            </Button>
          )}
          {modalType !== 'registration' && (
            <div className="ml-1 md:ml-1t">
              <Button
                dataTestid={testIdForRegistration}
                size="xs"
                onClick={() => {
                  router.push(RoutesConfig.register);
                }}
              >
                {t('signUp')}
              </Button>
            </div>
          )}
        </div>
        <div className="hidden md:flex">
          <Button
            variant="border"
            dataTestid={testIdForLogin}
            color="white"
            onClick={() => {
              router.push(RoutesConfig.login);
              sendHeaderLoginButtonEvent();
            }}
          >
            {t('signIn')}
          </Button>
          <div className="ml-1 md:ml-1t">
            <Button
              dataTestid={testIdForRegistration}
              onClick={() => {
                router.push(RoutesConfig.register);
                sendHeaderRegistrationButtonEvent();
              }}
            >
              {t('signUp')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
