import { SvgIcon } from './svg-icon';

type Props = {
  className?: string;
};

export const SidebarBurgerMinIcon: React.FC<Props> = ({ className }) => (
  <SvgIcon size={32} className={className} fill="none">
    <path
      d="M5.33203 8H26.6654"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 16H22.6654"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 24H18.6654"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
