export type LinkTitleCode = 'live' | 'games' | 'instant' | 'promo';

export type Link = { title: LinkTitleCode; href: string };

export const HEADER_LINKS: Link[] = [
  {
    title: 'games',
    href: '/games'
  },
  { title: 'instant', href: '/games/category/instant' },
  { title: 'live', href: '/games/category/live' },
  {
    title: 'promo',
    href: '/promo'
  }
];
