'use client';

import { useState, useLayoutEffect, useCallback, useRef, useEffect, useMemo } from 'react';
import { usePathname } from 'next/navigation';

import { debounce } from '@/shared/lib/debounce';

import { Link, HEADER_LINKS as links } from '../shared/header-links';
import { Item } from './item';
import { useScopedI18n } from '@/shared/locales/client';
import { Search } from './search';

interface HeaderMenuProps {
  headerRef: React.RefObject<HTMLDivElement | null>;
  isLoggedIn: boolean;
}

const SCREEN_SIZES = {
  LG: 1200,
  MEDIUM: 980
};

const RESERVED_SPACE = {
  LOGGED_IN: 460 + 160 + 20, // logo + buttons + padding
  LOGGED_OUT: 250 + 160 + 20 // logo + buttons + padding
};

const THREE_DOTS_SPACE = 60;
const LARGE_SCREEN_PADDING = 140; // 70 * 2

export const MenuSection: React.FC<HeaderMenuProps> = ({ headerRef, isLoggedIn }) => {
  const t = useScopedI18n('header');
  const pathname = usePathname();
  const ref = useRef<HTMLDivElement>(null);
  const [visibleLinks, setVisibleLinks] = useState<typeof links>(links);
  const [listVisible, setListVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [itemSizes, setItemSizes] = useState<number[]>([]);

  const reservedSpace = useMemo(
    () => (isLoggedIn ? RESERVED_SPACE.LOGGED_IN : RESERVED_SPACE.LOGGED_OUT),
    [isLoggedIn]
  );

  const calcItemSizes = () => {
    const sizes: number[] = [];
    ref.current?.querySelectorAll('a').forEach((item) => {
      sizes.push(item.offsetWidth);
    });
    setItemSizes(sizes);
  };

  useEffect(() => {
    calcItemSizes();
  }, []);

  const updateMenu = useCallback(() => {
    const headerWidth = headerRef.current?.offsetWidth ?? 0;
    if (headerWidth < SCREEN_SIZES.MEDIUM) {
      setVisibleLinks([]);
    }

    let availableSpace =
      headerWidth - reservedSpace - (headerWidth >= SCREEN_SIZES.LG ? LARGE_SCREEN_PADDING : 0);
    const newVisibleLinks: Link[] = [];

    for (let index = 0; index < itemSizes.length; index++) {
      const size = itemSizes[index];
      availableSpace -= size;

      if (availableSpace >= THREE_DOTS_SPACE) {
        newVisibleLinks.push(links[index]);
      } else {
        break;
      }
    }

    setVisibleLinks(newVisibleLinks);
  }, [headerRef, reservedSpace, itemSizes]);

  const debounceUpdateMenu = useMemo(() => debounce(updateMenu, 200), [updateMenu]);

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setListVisible(false);
    }
  }, []);

  const toggleDropdown = () => setListVisible((prev) => !prev);

  useLayoutEffect(() => {
    const handleResize = () => {
      debounceUpdateMenu();
      setListVisible(false); // Скрыть меню при изменении размера окна
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [debounceUpdateMenu, handleClickOutside]);

  useEffect(() => {
    updateMenu();
  }, [updateMenu]);

  return (
    <div ref={ref} className="hidden h-full w-full min-w-[10px] items-center justify-start md:flex">
      {visibleLinks.map(({ title, href }) => (
        <Item key={href} testIdModificator={title} href={href} active={pathname.endsWith(href)}>
          {t(title)}
        </Item>
      ))}
      <Search className="h-full content-center px-2" />
      {links.length > visibleLinks.length && (
        <div className="relative hidden h-full md:flex">
          <button
            className="typography-button-regular h-full cursor-pointer rounded-1 transition-colors duration-200 hover:bg-background-main hover:text-primary md:px-2"
            onClick={toggleDropdown}
          >
            <div className="w-[13px]">&hellip;</div>
          </button>
          {listVisible && (
            <div
              ref={dropdownRef}
              className="absolute right-0 top-[68px] w-[168px] overflow-hidden rounded-1 bg-background-main shadow-menuItems hover:bg-background-body"
            >
              {links.slice(visibleLinks.length).map(({ title, href }) => (
                <Item key={href} testIdModificator={title} href={href} isSubMenu={true}>
                  {t(title)}
                </Item>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
