'use client';

import Link from 'next/link';
import { ReactNode, useRef } from 'react';

import { useProfile } from '@/entities/profile';
import { getDataTestId } from '@/shared/lib/get-data-testid';

import { MenuSection } from './menu-section';
import { AuthSection } from './auth-section';
import { SidebarToggle } from './sidebar-toggle';

interface Props {
  showMenu?: boolean;
  logo: ReactNode;
  showBurgerToggle?: boolean;
}

export const Header: React.FC<Props> = ({ showMenu = true, logo, showBurgerToggle = true }) => {
  const headerRef = useRef(null);
  const { isLoggedIn } = useProfile();

  const testIdLogo = getDataTestId({
    block: 'header',
    nameForTargetElement: 'link',
    modification: 'logo'
  });

  return (
    <header
      ref={headerRef}
      className="fixed left-0 top-0 z-top4 h-[--height-header-mobile] w-full bg-background-body px-2 py-1 md:h-[--height-header] md:px-3 md:py-1/2"
    >
      <div className="m-auto flex h-4 items-center justify-between md:h-7">
        <div className="flex h-full items-center gap-5">
          {showBurgerToggle && <SidebarToggle />}
          <Link data-testid={testIdLogo} href="/">
            {logo}
          </Link>
          {showMenu && <MenuSection headerRef={headerRef} isLoggedIn={isLoggedIn} />}
        </div>
        <div className="flex h-4 items-center justify-end md:h-7">
          <AuthSection />
        </div>
      </div>
    </header>
  );
};
