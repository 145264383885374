'use client';

import React from 'react';

import { useScopedI18n } from '@/shared/locales/client';
import { useProfile } from '@/entities/profile';
import { Balance } from '@/entities/balance';
import {
  priceFormatWithoutCurrency,
  priceFormatWithoutCurrencyAndWithShortener
} from '@/shared/lib/price-formatters';

import { Item } from './item';
import { RoutesConfig } from '@/shared/routes/config';
import { usePathname } from 'next/navigation';
import { useMediaQuery } from 'react-responsive';

export const ProfileBalance: React.FC = () => {
  const t = useScopedI18n('header');
  const pathname = usePathname();
  const inGame = new RegExp(`^${RoutesConfig.casinoGames}(/|$)`).test(pathname);
  const isMobile = useMediaQuery({ query: '(max-width: 980px)' });

  const { profile } = useProfile();

  const balance = profile?.balance?.amount ?? 0;
  const bonusBalance = profile?.balance?.bonusAmount ?? 0;

  return (
    <Item
      href={RoutesConfig.profile}
      testIdModificator="balance"
      className="cursor-pointer md:flex md:items-center md:justify-between"
    >
      <h2 className="typography-button-small-regular hidden text-primary  md:typography-button-regular md:inline">
        {t('balance')}:
      </h2>
      <div className="typography-button-small-regular mr-1 md:typography-button-regular md:mr-0 md:flex-auto md:pl-1">
        {inGame ? (
          <span>{t('inGame')}</span>
        ) : (
          <Balance
            price={balance + bonusBalance}
            currency={profile?.currencyId}
            formatter={
              isMobile ? priceFormatWithoutCurrencyAndWithShortener : priceFormatWithoutCurrency
            }
          />
        )}
      </div>
    </Item>
  );
};
