import { HeaderSearchIcon } from '@/shared/ui';
import { GameSearchModal } from '@/widgets/casino/game-search/ui/modal';
import React, { useState } from 'react';

export const Search: React.FC<{ className?: string }> = ({ className }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearchModal = () => {
    setIsSearchOpen(true);
  };

  return (
    <div className={className}>
      <button onClick={toggleSearchModal}>
        <HeaderSearchIcon />
      </button>
      <GameSearchModal isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </div>
  );
};
