'use client';

import { useSidebar } from '@/entities/sidebar';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { SidebarBurgerIcon } from '@/shared/ui/icons/sidebar-burger';
import { SidebarBurgerMinIcon } from '@/shared/ui/icons/sidebar-burger-min';
import { usePathname } from 'next/navigation';

export const SidebarToggle: React.FC = () => {
  const {
    sidebarVisible,
    showSidebar,
    hideSidebar,
    toggleOverlaySidebar,
    overlaySidebarPath,
    overlaySidebarVisible
  } = useSidebar();

  const pathname = usePathname();
  const withOverlaySidebar = overlaySidebarPath.some((path) => pathname.includes(path));

  const getToggleHandler = () => {
    if (withOverlaySidebar) return () => toggleOverlaySidebar(!overlaySidebarVisible);
    if (sidebarVisible) return hideSidebar;
    return showSidebar;
  };

  return (
    <button
      onClick={getToggleHandler()}
      className="text-text-primary  hover:text-text-brand"
      data-testid={getDataTestId({
        block: 'header',
        nameForTargetElement: 'sidebar-toggle'
      })}
    >
      {withOverlaySidebar || sidebarVisible ? <SidebarBurgerIcon /> : <SidebarBurgerMinIcon />}
    </button>
  );
};
