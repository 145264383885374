'use client';

import { useProfile } from '@/entities/profile';
import { useAuth } from '@/entities/auth';

import { AccountInfo } from './account-info';
import { AuthButtons } from './auth-buttons';

export const AuthSection: React.FC = () => {
  const { ready: profileReady } = useProfile();
  const { isAuth, ready: authReady } = useAuth();

  if (!authReady || (isAuth && !profileReady)) {
    return null;
  }

  if (!isAuth) {
    return <AuthButtons />;
  }

  return <AccountInfo />;
};
