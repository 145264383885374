import { MouseEventHandler } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import { getDataTestId } from '@/shared/lib/get-data-testid';

type Props = {
  children?: React.ReactNode;
  active?: boolean;
  href?: string;
  onClick?: MouseEventHandler;
  className?: string;
  isSubMenu?: boolean;
  testIdModificator?: string;
};

export const Item: React.FC<Props> = ({
  children,
  active,
  href = '#',
  className: exClassName,
  isSubMenu,
  onClick,
  testIdModificator = ''
}) => {
  const dataTestId = testIdModificator
    ? getDataTestId({
        block: 'header',
        nameForTargetElement: 'link',
        modification: testIdModificator
      })
    : '';

  const className = classNames(
    exClassName,
    'flex h-full hover:text-text-brand transition-colors duration-200 items-center md:px-2 whitespace-nowrap md:typography-button-regular group',
    active ? 'cursor-default text-text-brand' : 'text-text-primary',
    {
      '!h-4t px-2 py-12px': isSubMenu
    }
  );

  return (
    <Link className={className} href={href} onClick={onClick} data-testid={dataTestId}>
      {children}
    </Link>
  );
};
